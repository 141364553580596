import React from 'react'
import { CLIENT_URL } from '../Data/apiData'

export default function PriyoLogin() {
  return (
    <div className='w-screen h-screen bg-slate-900 flex items-center justify-center'>
        <div className='p-12 md:p-24 min-h-[300px] flex flex-col items-center justify-center bg-slate-800 rounded-lg m-8 shadow text-gray-200'>
            <img className='w-48 md:w-64 mb-4' src="https://i.postimg.cc/15M8db7S/penguin.png"/>
            <p className='font-semibold text-xl md:text-3xl '>Get Started with <span className='text-amber-500'>Penguin</span></p>
            <p className='text-sm md:text-lg mb-8 text-gray-200'>The official LLM application of Priyo</p>
            <div className='grid grid-cols-2 gap-4'>
                <button onClick={()=>window.location.href="https://accounts.priyo.com/register/?next="+CLIENT_URL} className='px-4 py-2 text-sm md:text-lg rounded bg-gray-700 border border-gray-700 hover:border-amber-500'>Create Account</button>
                <button onClick={()=>window.location.href="https://accounts.priyo.com/login/?next="+CLIENT_URL} className='flex-1 px-4 text-sm md:text-lg py-2 rounded text-gray-800 bg-amber-500 border border-gray-700 hover:border-amber-500'>Login</button>
            </div>
        </div>
    </div>
  )
}
