import React from 'react'
import MDEditor from '@uiw/react-md-editor'
export default function UserChatLong({message,avatar}) {
  return (
    <div className="flex w-full justify-end my-2">
        
        <div href="#" class="w-4/5  p-4 border-2 border-amber-400 text-white border rounded-lg shadow-md">
            <MDEditor.Markdown className='dark:hidden text-xs' source={message.content} style={{ background: "transparent",color:"white",fontFamily:"Poppins" }} />
            <MDEditor.Markdown className='hidden dark:block text-xs' source={message.content} style={{ background: "transparent",fontFamily:"Poppins" }} />
        </div>
        <img className="w-12 h-12 p-1 ml-2 rounded-full bg-gray-200" src={avatar}/>
    </div>
  )
}
