import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/SideBar';
import axios from 'axios';

export default function MyIssues() {
  const [loading, setLoading] = useState(false);
  const [allIssues, setAllIssues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [currentIssue, setCurrentIssue] = useState(null);
  const [currentTab, setCurrentTab] = useState('open');
  const API_URL = 'https://penguapi.priyolab.net';
  const userID = localStorage.getItem('penguin-userid');

  const getAllIssues = async () => {
    setLoading(true);
    const res = await axios.get(`${API_URL}/api/v1/users/${userID}/issues`);
    console.log(res.data);
    setAllIssues(res.data);
    setLoading(false);
  };

  const createIssue = async () => {
    try {
      console.log({
        feedback: feedback,
      });
      const response = await axios.put(`${API_URL}/api/v1/issues/${currentIssue.id}/user`, {
        feedback: feedback,
      });
      console.log('Issue edited:', response.data);

      window.location.reload();
    } catch (error) {
      console.error('Error editing issue:', error);
    }
  };

  useEffect(() => {
    getAllIssues();
  }, []);

  const filteredIssues = allIssues.filter((issue) => issue.status === currentTab);

  if (loading) {
    return (
      <div className='w-screen min-h-screen bg-slate-900'>
        <h2 className='text-xl text-white font-semibold'>Loading....</h2>
      </div>
    );
  }

  return (
    <div className='w-screen min-h-screen bg-slate-900'>
      <Sidebar />
      <div className='md:pl-64 p-8'>
        <h2 className='text-2xl font-semibold text-white p-4'>🚧 My issues</h2>
        <div className='w-full p-4'>
          <div className='flex justify-center mb-4'>
            <button
              onClick={() => setCurrentTab('open')}
              className={`px-4 py-2 rounded ${currentTab === 'open' ? 'bg-amber-500' : 'bg-gray-700'}`}
            >
              Open Issues
            </button>
            <button
              onClick={() => setCurrentTab('resolved')}
              className={`text-white px-4 py-2 rounded ml-2 ${currentTab === 'resolved' ? 'bg-amber-500' : 'bg-gray-700'}`}
            >
              Resolved Issues
            </button>
          </div>
          {filteredIssues.length === 0 && (
            <div className='bg-gray-800 p-4 rounded-lg text-gray-200'>
              <p className='text-sm'>Nothing to see here 🤷🏻‍♂️</p>
            </div>
          )}
          {filteredIssues.map((a) => (
            <div className='w-full p-6 my-2 bg-slate-800 rounded shadow-md border border-gray-700 text-gray-200'>
              <div className='w-full flex justify-end'>
                {a.status === 'open' && (
                  <span className='bg-amber-100 text-amber-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-amber-900 dark:text-amber-300'>
                    Open
                  </span>
                )}
                {a.status === 'resolved' && (
                  <span className='bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300'>
                    Resolved
                  </span>
                )}
              </div>
              <h1 className='text-lg font-semibold text-white'>{a.message_content}</h1>
              <h3 className='text-xs'>{a.feedback}</h3>
              {a.status === 'resolved' && (
                <h3 className='text-xs p-2 border border-green-700 my-2 text-green-200 rounded'>{a.response}</h3>
              )}
              {a.status !== 'resolved' && (
                <button
                  onClick={() => {
                    setCurrentIssue(a);
                    setFeedback(a.feedback);
                    setShowModal(true);
                  }}
                  className='text-xs text-gray-800 mt-4 font-semibold rounded px-4 py-2 bg-amber-500 hover:bg-amber-600'
                >
                  Update Issue
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <div className='z-3 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
          {loading && (
            <div className='bg-gray-800 w-[500px] p-4 rounded-lg shadow-lg border border-gray-600'>
              <p className='text-white mb-2'>Updating Issue...</p>
            </div>
          )}
          {!loading && (
            <div className='bg-gray-800 w-[500px] p-4 rounded-lg shadow-lg border border-gray-600'>
              <h2 className='text-white mb-2'>Edit Issue</h2>
              <textarea
                className='w-full p-2 text-xs bg-gray-700 text-white rounded-lg mb-2'
                placeholder='Describe the issue...'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              <div className='flex justify-start'>
                <button
                  onClick={createIssue}
                  className='mr-2 text-xs text-gray-900 bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg p-2'
                >
                  Submit
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className='text-xs text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg p-2'
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
