import React, { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { MdReportProblem } from 'react-icons/md';
import { SiPolestar } from "react-icons/si";
export default function AssistantChatLong({ message, avatar, onCreateIssue}) {
    const [showIssueModal, setShowIssueModal] = useState(false);
    const [showImproveModal, setShowImproveModal] = useState(false);
    const [feedback, setFeedback] = useState("");

    const handleReportIssue = () => {
        setShowIssueModal(true);
    };
    const handleImproveAnswer = () => {
        setShowImproveModal(true);
    };

    const handleSubmitFeedback = () => {
        onCreateIssue(feedback, message);
        setShowIssueModal(false);
        setShowImproveModal(false);
        setFeedback("");
    };


    return (
        <div className="flex flex-col my-4">
            <div className="flex items-start">
                <img className="w-12 h-12 p-1 mr-4 rounded-full bg-gray-200" src={avatar} alt="Assistant Avatar" />
                <div className="w-4/5 p-4 border-2 rounded-lg shadow-md border-gray-600">
                    <MDEditor.Markdown className="text-xs" source={message.content} style={{ background: "transparent", fontFamily: "Poppins", color: "white" }} />
                    {message.id && <div className='flex space-x-2 items-center'>
                        <button onClick={handleReportIssue} className="z-5 mt-2 flex items-center text-xs text-white bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg p-2">
                            <MdReportProblem className="mr-2" /> Create Issue
                        </button>
                        <button onClick={handleImproveAnswer} className="z-5 mt-2 flex items-center text-xs text-white bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg p-2">
                            <SiPolestar className="mr-2" /> Improve Answer
                        </button>
                    </div>}
                    
                </div>
            </div>
            {showIssueModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-gray-800 w-[500px] p-4 rounded-lg shadow-lg border border-gray-600">
                        <h2 className="text-white mb-2">Create Issue</h2>
                        <textarea
                            className="w-full p-2 bg-gray-700 text-white rounded-lg mb-2"
                            placeholder="Describe the issue..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                        <div className="flex justify-start">
                            <button onClick={handleSubmitFeedback} className="mr-2 text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg p-2">
                                Submit
                            </button>
                            <button onClick={() => setShowIssueModal(false)} className="text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg p-2">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showImproveModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-gray-800 w-[500px] p-4 rounded-lg shadow-lg border border-gray-600">
                        <h2 className="text-white mb-2">Improve the answer</h2>
                        <textarea
                            className="w-full p-2 bg-gray-700 text-white rounded-lg mb-2"
                            placeholder="Provide your answer..."
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                        <div className="flex justify-start">
                            <button onClick={handleSubmitFeedback} className="mr-2 text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg p-2">
                                Submit
                            </button>
                            <button onClick={() => setShowImproveModal(false)} className="text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg p-2">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
