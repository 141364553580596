import Congratulations from "./Pages/Congratulations";
import UserOnboarding from "./Pages/UserOnboarding";
import Login from "./Pages/Login";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Chatter from "./Pages/Chatter";
import MyIssues from "./Pages/MyIssues";
import PriyoLogin from "./Pages/PriyoLogin";
import Help from "./Pages/Help";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<UserOnboarding />} />
      <Route path="/login" element={<PriyoLogin />} />
      <Route path="/chat" element={<Chatter />} />
      <Route path="/issues" element={<MyIssues />} />
      <Route path="/congratulations" element={<Congratulations />} />
      <Route path="/help" element={<Help />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
