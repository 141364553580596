import React, { useEffect, useState } from 'react'
import { TbSocial } from 'react-icons/tb'
import { MdCreate } from 'react-icons/md'
import { CgAdd, CgClose, CgProfile } from 'react-icons/cg'
import { BiLogOut, BiVideo } from 'react-icons/bi'
import { BsShop } from 'react-icons/bs'
import {BsPlugin} from 'react-icons/bs'
import {RiDashboardFill} from 'react-icons/ri'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import { GiHelp } from 'react-icons/gi'

export default function Sidebar({active}) {
  const navigator=useNavigate()
  const [theme, setTheme] = useState('')
  const [lan, setLan] = useState('english')

  const [sidebarActive,setSidebarActive]=useState(false)

  const changeTheme = () => {
    console.log("change")
    if (localStorage.getItem('color-theme') === 'light') {
      console.log("dark")
      localStorage.setItem('color-theme', 'dark')
      setTheme("dark")
      document.documentElement.classList.add('dark');
    } else {
      localStorage.setItem('color-theme', 'light')
      setTheme('light')
      document.documentElement.classList.remove('dark');
      console.log("light")
    }
  }
  const changeLanguage = () => {
    console.log("change")
    if (localStorage.getItem('language') === 'english') {
      console.log("bangla")
      localStorage.setItem('language', 'bangla')
      setLan("bangla")
      document.documentElement.classList.add('bangla');
    } else {
      localStorage.setItem('language', 'english')
      setLan('english')
      document.documentElement.classList.remove('bangla');
      console.log("english")
    }
    window.location.reload()
  }
  useEffect(() => {
    if (localStorage.getItem('color-theme') === 'light') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
    if(localStorage.getItem('language')==='english'){
      setLan('english')
    }else{
        setLan('bangla')
    }
  }, [])
  if(!sidebarActive && window.screen.width<600){
    return(
      <aside id="default-sidebar" class="fixed w-12 top-0 left-0 z-50 h-screen " aria-label="Sidenav">
        <button onClick={()=>setSidebarActive(true)} variant="outline" className="fixed top-4 left-0 p-2 border border-gray-700">
          <HamburgerMenuIcon width={16} height={16} className='text-white'/>
        </button>
      </aside>
  )
  }

  return (
    <div className='z-10'>
      <aside id="default-sidebar" class="fixed top-0 left-0 w-64 h-screen" aria-label="Sidenav">
        {sidebarActive && window.screen.width<600 && <button onClick={()=>setSidebarActive(false)} variant="outline" className="fixed z-50 top-4 left-56 text-white">
          <CgClose size={16}/>
        </button>}
        <div class="overflow-y-auto py-5 px-3 h-full  border-r  bg-gray-800 border-gray-700" >
          <div className='flex items-center mb-5 justify-start w-full' onClick={() => navigator("/")}>
            {/* <img alt="logo" className='w-12 h-12 mr-2' src={logo} /> */}
            <h2 className='text-xl font-bold text-gray-100'>🐧Penguin</h2>
          </div>
     
          <div className="divider"></div>
          <div className='flex mb-4 w-full'>
            <button className='px-4 text-xs py-2 mt-2 border-2 border-amber-500 bg-amber-500 '>Payment Bot</button>
          </div> 
          <button onClick={()=>navigator("/chat")} className='p-4 bg-amber-500 rounded-xl text-sm font-semibold flex items-center '><CgAdd className='text-lg mr-2'/> New Conversation</button>
          {/* <h2 className='text-sm my-4 text-white font-semibold'> Recent Chats</h2>
          <ul class="space-y-1">
            <li>
              <button onClick={()=>{}} class="w-full whitespace-nowrap text-sm pr-4 overflow-hidden flex items-center p-2 font-normal rounded-lg text-white hover:bg-gray-700 " >
                How to more efficiently use Upwork and get most customers
              </button>
            </li>
            <li>
              <button onClick={()=>{}} class="w-full whitespace-nowrap text-sm pr-4 overflow-hidden flex items-center p-2 font-normal rounded-lg text-white hover:bg-gray-700 " >
                How businesses are failing these days
              </button>
            </li>
            
            
          </ul> */}
          
        </div>

        {/** BOTTOM ICONS */}
        <div class="absolute bottom-0 left-0 p-4 w-full flex flex-col bg-gray-800 z-20 border-r border-gray-700 border-t border-gray-700">
            <div>
              <button onClick={()=>{navigator('/help')}} class="w-full flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700" >
                <GiHelp size={24} color={theme === "dark" ? "white" : "grey"} />
                <span class="ml-3">Help</span>
              </button>
            </div>
            
            <div>
              <button onClick={()=>{navigator('/issues')}} class="w-full flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700" >
                <MdCreate size={24} color={theme === "dark" ? "white" : "grey"} />
                <span class="ml-3">My Issues</span>
              </button>
            </div>
            <div>
              <button onClick={()=>{navigator('/help')}} class="w-full flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700">
                <BiVideo size={24} color={theme === "dark" ? "white" : "grey"} />
                <span class="ml-3">Tutorials</span>
              </button>
            </div>
        </div>
      </aside>
    </div>
  )
}
