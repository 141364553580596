import React from 'react'
import { CLIENT_URL } from '../Data/apiData'
import Sidebar from '../Components/SideBar'
import { TbCopy } from "react-icons/tb";
export default function Help() {
  return (
    <div className='md:pl-64 pr-4 w-screen min-h-screen bg-slate-900 text-gray-200 flex flex-col items-center justify-start'>
        <Sidebar/>
        <h2 className='text-2xl md:text-4xl my-6 font-semibold px-2'>Contact <span className='text-amber-500'>Us</span></h2>
        <div className='flex items-center'>
            <p className='px-2'>Send us a mail at <span className='px-4 mx-2 py-2 rounded bg-slate-700'>penguin@priyo.com</span></p>
            <button className='p-3 rounded bg-slate-700 hover:bg-slate-600' onClick={()=>{navigator.clipboard.writeText("penguin@priyo.com");}}><TbCopy/></button>
        </div>
      
        
    </div>
  )
}
