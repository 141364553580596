import React, { useState, useEffect, useRef } from "react";
import AssistantChatLong from "../Components/AssistantChatLong";
import UserChatLong from "../Components/UserChatLong";
import axios from "axios";
import { IoSendSharp } from "react-icons/io5";
import Sidebar from "../Components/SideBar";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";

const API_URL = "https://penguapi.priyolab.net";


const Chatter = () => {
  const navigator=useNavigate()
  const bottomRef = useRef();
  const [currentUserChat, setCurrentUserChat] = useState(null);
  const [generatingChat, setGeneratingChat] = useState(false);
  const [chatId, setChatId] = useState(null); // State to store chat ID
  const USER_ID = localStorage.getItem("penguin-userid");
  const [conversation, setConversation] = useState([
    {
      role: "assistant",
      content: {
        content: `Hello. Please tell me how I can help you`,
        id: null,
      },
    },
  ]);

  const createChat = async (message) => {
    try {
      console.log("USER ID : ",USER_ID)
      const response = await axios.post(`${API_URL}/api/v1/chats`, {
        user_id: USER_ID, // Replace with actual user ID
        first_message: message,
      });
      console.log(response.data);
      setChatId(response.data.id); // Save chat ID

      const agentConvBody = {
        role: "assistant",
        content: response.data.response,
      };

      setConversation((conversation) => {
        const updatedConversation = [...conversation];
        const lastUserMessageIndex = updatedConversation
          .slice()
          .reverse()
          .findIndex((msg) => msg.role === "user");

        if (lastUserMessageIndex !== -1) {
          const actualIndex = updatedConversation.length - 1 - lastUserMessageIndex;
          updatedConversation[actualIndex].content = response.data.query
        }

        return [...updatedConversation, agentConvBody];
      });

    } catch (error) {
      alert("Our penguin had an issue. We just fixed it for you!")
      window.location.reload()
      console.error("Error creating chat:", error);
    }
  };

  const updateChat = async (message) => {
    try {
      const response = await axios.put(`${API_URL}/api/v1/chats/${chatId}`, {
        content: message,
      });
      console.log(response.data);
      const agentConvBody = {
        role: "assistant",
        content: response.data.response,
      };

      setConversation((conversation) => {
        const updatedConversation = [...conversation];
        const lastUserMessageIndex = updatedConversation
          .slice()
          .reverse()
          .findIndex((msg) => msg.role === "user");

        if (lastUserMessageIndex !== -1) {
          const actualIndex = updatedConversation.length - 1 - lastUserMessageIndex;
          updatedConversation[actualIndex].content = response.data.query
        }

        return [...updatedConversation, agentConvBody];
      });

    } catch (error) {
      console.error("Error updating chat:", error);
    }
  };

  const createIssue = async (feedback, message) => {
    const messageIndex = conversation.findIndex((msg) => msg.content.id === message.id);
      const previousUserMessage = conversation
        .slice(0, messageIndex)
        .reverse()
        .find((msg) => msg.role === "user");

      const previousMessageContent = previousUserMessage
        ? previousUserMessage.content
        : { id: null, content: "" };

    try {
      console.log({
        user_id: USER_ID,
        chat_id: chatId,
        message_id: previousMessageContent.id,
        message_content: previousMessageContent.content,
        feedback: feedback,
      });
      const response = await axios.post(`${API_URL}/api/v1/issues`, {
        user_id: USER_ID,
        chat_id: chatId,
        message_id: previousMessageContent.id,
        message_content: previousMessageContent.content,
        feedback: feedback,
      });
      console.log("Issue created:", response.data);
    } catch (error) {
      console.error("Error creating issue:", error);
    }
  };

  const handleCreateIssue = (feedback, message) => {
    createIssue(feedback, message);
  };

  const createConversation = async () => {
    if (generatingChat) {
      return;
    }
    const userConvBody = {
      role: "user",
      content: {
        content: currentUserChat,
        id: null
      }
    };
    setConversation((conversation) => [...conversation, userConvBody]);

    setGeneratingChat(true);
    setCurrentUserChat("");

    if (!chatId) {
      await createChat(currentUserChat);
    } else {
      console.log(currentUserChat);
      await updateChat(currentUserChat);
    }

    setGeneratingChat(false);
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [conversation]);
  useEffect(()=>{
    if(!localStorage.getItem("penguin-userid")){
      navigator("/login")
    }
  },[])
  return (
    <div className="z-1 w-screen h-screen bg-slate-900 flex items-center justify-center md:pl-64">
      <Sidebar />
      {/* <div className="relative w-4/5 min-h-[600px] pt-24 md:min-h-[800px] no-scrollbar border rounded-xl shadow-md h bg-gray-800 border-gray-700 "> */}
      <div className="p-12 py-24 md:py-12 h-full w-full bg-slate-900">
        <div className="relative w-full h-full no-scrollbar border rounded-xl shadow-md h bg-gray-800 border-gray-700 "> 
          <div className="absolute no-scrollbar pb-20 top-0 flex p-2 flex-col w-full max-h-[600px] md:max-h-[750px] overflow-y-scroll no-scrollbar">
            {conversation.map((a, idx) => {
              if (a.role === "assistant") {
                return (
                  <AssistantChatLong
                    key={idx}
                    message={a.content}
                    avatar="https://i.postimg.cc/15M8db7S/penguin.png"
                    onCreateIssue={handleCreateIssue}
                  />
                );
              } else if (a.role === "user") {
                return (
                  <UserChatLong
                    key={idx}
                    message={a.content}
                    avatar="https://i.postimg.cc/s23J2htT/4.png"
                  />
                );
              }
            })}
            {generatingChat && (
              <AssistantChatLong
                message={{
                  content: "Hmmmmmm.......Let me think.....",
                  id: null,
                }}
                avatar="https://i.postimg.cc/15M8db7S/penguin.png"
                onCreateIssue={handleCreateIssue}
              />
            )}
            <div ref={bottomRef}></div>
          </div>
          <div className="absolute flex items-center justify-between -bottom-1 bg-slate-700 w-full p-2 rounded-b-lg">
            <textarea
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  createConversation();
                }
              }}
              type="text"
              rows={1}
              className="block w-full md:w-full p-4 text-sm border rounded-lg bg-slate-800 border-gray-600 placeholder-gray-400 text-white focus:ring-amber-500 focus:border-amber-500"
              placeholder={"Enter your chat"}
              value={currentUserChat}
              onChange={(e) => setCurrentUserChat(e.target.value)}
            />
            <div className="flex">
              <button
                onClick={createConversation}
                className="h-full mx-1 text-white bg-amber-700 hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg text-sm p-4 dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800"
              >
                <IoSendSharp />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatter;
