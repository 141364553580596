import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_URL, AUTH_URL } from "../Data/apiData";

export default function UserOnboarding() {
  const navigator = useNavigate();
  const [currentStage, setCurrentStage] = useState(1);
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [city, setCity] = useState(null);
  const [interest, setInterest] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [otherPlatform, setOtherPlatform] = useState(null);
  const [otherInterest, setOtherInterest] = useState(null);
  const [enteredName, setEnteredName] = useState(false);

  const getUser = async (session) => {
    setLoading(true);
    try {
      const res = await axios.get(AUTH_URL, {
        headers: {
          Authorization: "token " + session,
        },
      });
      console.log(res.data);
      setEmail(res.data.email);
      await findUserInServer(res.data.email);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const findUserInServer = async (email) => {
    try {
      const res = await axios.get(API_URL + "/api/v1/users/email/" + email);
      if (res.status === 200) {
        localStorage.setItem("penguin-userid", res.data.id);
        navigator("/chat");
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        console.log("User not found, proceeding with onboarding.");
      } else {
        console.error(e);
      }
    }
  };

  //     useEffect(() => {
  //     const token = localStorage.getItem('auth_token');
  //     const storedUserData = localStorage.getItem('user_data');

  //     if (!token) {
  //       navigator('/login');
  //     } else {
  //       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  //     }
  //   }, [navigator]);

  const submitUser = async () => {
    const reqBody = {
      name: name,
      location: city,
      email: email,
      interest: [otherInterest != null ? otherInterest : interest],
      platform: [otherPlatform != null ? otherPlatform : platform],
    };
    console.log(reqBody);
    try {
      const res = await axios.post(
        API_URL + "/api/v1/users/" + email + "/exists",
        reqBody
      );
      console.log(res);
      localStorage.setItem("penguin-userid", res.data.id);
      navigator("/congratulations");
    } catch (e) {
      alert(e);
      return;
    }
  };

  const getEmailFromToken = async () => {
    const param = window.location.search;
    const urlParams = new URLSearchParams(param);
    const session = urlParams.get("q");
    if (session) {
      await getUser(session);
    } else {
      navigator("/login");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_data");
    navigator("/");
  };
  
  useEffect(() => {
    if (localStorage.getItem("penguin-userid")) {
      navigator("/chat");
    } else {
      getEmailFromToken();
    }
  }, []);

  useEffect(() => {}, []);
  if (loading) {
    return (
      <div className="w-screen min-h-screen bg-slate-900 flex flex-col items-center justify-center">
        <p>Loading.....</p>
      </div>
    );
  }
  return (
    <div className="w-screen min-h-screen bg-slate-900 flex flex-col items-center justify-center">
      <Helmet>
        <meta property="og:title" content="Penguin" />
        <meta
          property="og:description"
          content="Your helper AI for all your questions"
        />
        <meta
          property="og:image"
          content="https://i.postimg.cc/Nj0CjXvW/Add-a-heading.png"
        />
      </Helmet>
      {currentStage == 1 && (
        <motion.div className="w-4/5 ">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 0.5 }}
            className="text-gray-200 text-3xl font-semibold"
          >
            Welcome to <span className="text-primary-500">PenguinLLM</span>
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 1.5 }}
            className="text-gray-200 text-xl font-semibold"
          >
            Let's get to know you...
          </motion.h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 3 }}
            className="flex"
          >
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Enter your name"
              className="w-full text-gray-200 bg-slate-700 p-4 text-sm md:text-xl my-4 border-0 active:border-primary-500 focus:border-primary-500"
            />
            <button
              onClick={() => {
                if (name) {
                  setCurrentStage(2);
                } else {
                  alert("Please enter your name");
                }
              }}
              className="py-2 px-4 md:py-4 md:px-8 text-xs md:text-lg bg-primary-500 my-4 border-4 border-slate-700 hover:bg-primary-600"
            >
              Enter
            </button>
          </motion.div>
        </motion.div>
      )}
      {/* {currentStage==2 && <motion.div className='w-4/5 '>
            <motion.h2 initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1.3}} className='text-gray-200 text-3xl font-semibold'>Hello,<span className='text-primary-500'>{name}</span></motion.h2>
            <motion.h2 initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1.3,delay:1.3}} className='text-gray-200 text-xl font-semibold'>What is your email?</motion.h2>
            <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1.3,delay:3}} className='flex'>
                <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" placeholder='Enter your email' className='w-full text-gray-200 bg-slate-700 p-4 text-sm md:text-xl my-4 border-0 active:border-primary-500 focus:border-primary-500'/>
                <button onClick={()=>{
                    setCurrentStage(3)
                }} className='py-2 px-4 md:py-4 md:px-8 text-xs md:text-lg bg-primary-500 my-4 border-4 border-slate-700 hover:bg-primary-600'>Enter</button>
                
            </motion.div>
        </motion.div>} */}
      {currentStage == 2 && (
        <motion.div className="w-4/5 ">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="text-gray-200 text-3xl font-semibold"
          >
            Hello, <span className="text-primary-500">{name}</span>
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 1.3 }}
            className="text-gray-200 text-xl font-semibold"
          >
            Which city are you in?
          </motion.h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 3 }}
            className="flex"
          >
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type="text"
              placeholder="Enter your city"
              className="w-full text-gray-200 bg-slate-700 p-4 text-sm md:text-xl my-4 border-0 active:border-primary-500 focus:border-primary-500"
            />
            <button
              onClick={() => {
                if (city) {
                  setCurrentStage(3);
                } else {
                  alert("Please enter your city");
                }
              }}
              className="py-2 px-4 md:py-4 md:px-8 text-xs md:text-lg bg-primary-500 my-4 border-4 border-slate-700 hover:bg-primary-600"
            >
              Enter
            </button>
          </motion.div>
        </motion.div>
      )}
      {currentStage == 3 && (
        <motion.div className="w-4/5 my-4 md:my-0">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="text-gray-200 text-3xl font-semibold"
          >
            Hello, <span className="text-primary-500">{name}</span>{" "}
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="text-gray-200 text-2xl mb-8 font-semibold"
          >
            Based In {city}
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 1.3 }}
            className="text-gray-200 mb-6 text-xl font-semibold"
          >
            What are some of your interests?
          </motion.h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 3 }}
            className="grid grid-cols-1 md:grid-cols-4  gap-2 md:gap-6"
          >
            <motion.div
              onClick={() => setInterest("Software Development")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Software Development"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Software Development
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Digital Marketing")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Digital Marketing"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Digital Marketing
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Graphics Design")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Graphics Design"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Graphics Design
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Video Editing")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Video Editing"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Video Editing
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Content Creation")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Content Creation"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Content Creation
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Presentation Creation")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Presentation Creation"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Presentation Creation
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Animation")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Animation"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Animation
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setInterest("Other")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                interest == "Other"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Other..
              </h6>
            </motion.div>
          </motion.div>
          {interest != null && interest != "Other" && (
            <button
              onClick={() => setCurrentStage(4)}
              className="py-4 px-8 bg-primary-500 my-4 text-white font-semibold text-lg hover:bg-primary-600"
            >
              Enter
            </button>
          )}
          {interest == "Other" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.3 }}
              className="flex"
            >
              <input
                onChange={(e) => setOtherInterest(e.target.value)}
                type="text"
                placeholder="Enter your interest"
                className="w-full text-gray-200 bg-slate-700 p-4 text-sm md:text-xl my-4 border-0 active:border-primary-500 focus:border-primary-500"
              />
              <button
                onClick={() => {
                  if (otherInterest) {
                    setCurrentStage(4);
                  } else {
                    alert("Please enter your interest");
                  }
                }}
                className="py-2 px-4 md:py-4 md:px-8 text-xs md:text-lg bg-primary-500 my-4 border-4 border-slate-700 hover:bg-primary-600"
              >
                Enter
              </button>
            </motion.div>
          )}
        </motion.div>
      )}
      {currentStage == 4 && (
        <motion.div className="w-4/5 my-4 md:my-0">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="text-gray-200 text-3xl font-semibold"
          >
            Hello, <span className="text-primary-500">{name}</span>{" "}
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="text-gray-200 text-2xl mb-8 font-semibold"
          >
            Based In {city}
          </motion.h2>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 1.3 }}
            className="text-gray-200 mb-6 text-xl font-semibold"
          >
            Is there any specific platform you use?
          </motion.h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3, delay: 3 }}
            className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-6"
          >
            <motion.div
              onClick={() => setPlatform("Fiverr")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "Fiverr"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Fiverr
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("Upwork")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "Upwork"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Upwork
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("Freelancer")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "Freelancer"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Freelancer
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("TopTal")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "TopTal"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                TopTal
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("PeoplePerHour")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "PeoplePerHour"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                PeoplePerHour
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("SimplyHired")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "SimplyHired"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                SimplyHired
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("Guru")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "Guru"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Guru
              </h6>
            </motion.div>
            <motion.div
              onClick={() => setPlatform("Other")}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={
                platform == "Other"
                  ? `flex items-center justify-center p-4 md:p-6 text-white bg-primary-600 rounded-lg shadow-lg cursor-pointer border-2 border-primary-600`
                  : `flex items-center justify-center p-4 md:p-6 bg-slate-700 rounded-lg shadow-lg cursor-pointer border-2 border-slate-600 hover:border-primary-500`
              }
            >
              <h6 className="text-sm md:text-lg font-semibold text-center text-gray-200">
                Other..
              </h6>
            </motion.div>
          </motion.div>
          {platform != null && platform != "Other" && (
            <button
              onClick={submitUser}
              className="py-4 px-8 bg-primary-500 my-4 text-white font-semibold text-lg hover:bg-primary-600"
            >
              Enter
            </button>
          )}
          {platform == "Other" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.3 }}
              className="flex"
            >
              <input
                onChange={(e) => setOtherPlatform(e.target.value)}
                type="text"
                placeholder="Enter your platform"
                className="w-full text-gray-200 bg-slate-700  text-sm md:text-xl my-4 border-0 active:border-primary-500 focus:border-primary-500"
              />
              <button
                onClick={() => {
                  if (otherPlatform) {
                    submitUser();
                  } else {
                    alert("Please enter your platform");
                  }
                }}
                className="py-2 px-4 md:py-4 md:px-8 text-xs md:text-lg bg-primary-500 my-4 border-4 border-slate-700 hover:bg-primary-600"
              >
                Enter
              </button>
            </motion.div>
          )}
        </motion.div>
      )}
    </div>
  );
}
