import React,{useState,useEffect} from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'
export default function Congratulations() {
    const navigator=useNavigate()
    const [screenHeight,setScreenHeight]=useState(window.screen.height)
    const [screenWidth,setScreenWidth]=useState(window.screen.width)


  return (
    <div className='w-screen overflow-x-hidden h-screen flex flex-col items-center justify-center bg-slate-900 p-6'>
        <Confetti/>
        <div class="z-20 flex max-w-xl flex-col items-center justify-center w-full px-2 py-12 md:p-24 bg-slate-700 border border-slate-600 rounded-lg shadow ">
            <h5 class="mb-2 text-xl md:text-2xl font-bold tracking-tight text-white">🎉🎉 Congratulations!🎉🎉</h5>
            <p class="text-sm md:text-lg font-normal text-center  text-gray-300">You have been onboarded on PenguinLLM</p>
            <button onClick={()=>navigator("/chat")} class="inline-flex items-center mt-4 px-4 py-2 text-md font-medium text-center text-white bg-amber-500 rounded-lg hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-amber-300 dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800">
            Start Conversation
            </button>
        </div>
        
    </div>
 

  )
}
